import React, { useState } from "react";
import {
  Button,
  ButtonVariants,
  Input,
  InputVariants,
  TextAtom,
  TextVariants,
} from "../../../../../../../atoms";
import { isEditFloorPlanAllowed } from "../../utils";
import "./Floorplan.scss";

function AddFloorPlanName(props) {
  const {
    handleCreateFloorplan,
    getAllFloorPlansOfTheSite,
    siteDetail,
  } = props;
  const [floorplanName, setFloorplanName] = useState("");

  //console.log(siteDetail);
  const handleOnChangefloorplanName = (e) => {
    setFloorplanName(e.target.value);
  };
  const renderBasedOnEditFloorPlanPermissions = () => {
    //console.log(isEditFloorPlanAllowed(siteDetail?.permissions));
    if (isEditFloorPlanAllowed(siteDetail?.permissions))
      return addNewFloorPlan();
    else return noFloorplansMessage();
  };

  const noFloorplansMessage = () => {
    return (
      <div className="container_floorplan-name">
        <TextAtom variant={TextVariants.extraLarge}>
          No Floorplans Available for this site
        </TextAtom>
      </div>
    );
  };
  const addNewFloorPlan = () => {
    return (
      <div className="container_floorplan-name">
        <TextAtom variant={TextVariants.extraLarge}>Add a floor plan</TextAtom>
        <Input
          className="create-floorplan__input-name"
          name="floorplan name"
          variant={InputVariants.text}
          value={floorplanName}
          label={"Floorplan Name"}
          onChange={(e) => handleOnChangefloorplanName(e)}
        />
        <Button
          variant={
            floorplanName.trim()
              ? ButtonVariants.success
              : ButtonVariants.disabled
          }
          className="create-floorplan__button"
          onClick={() => handleCreateFloorplan(floorplanName)}
        >
          Create
        </Button>
        <Button
          variant={ButtonVariants.successOutlined}
          onClick={() => getAllFloorPlansOfTheSite()}
        >
          Show All
        </Button>
      </div>
    );
  };
  return renderBasedOnEditFloorPlanPermissions();
}

export default AddFloorPlanName;
