import React, { useEffect, useState, useCallback, useRef  } from "react";
import "./PostCard.scss";
import classnames from "classnames";
import {
  Input,
  InputVariants,
  TextAtom,
  TextSizes,
  TextVariants,
  TextWeights,
} from "../../atoms";

import {CommentCard} from "../../molecules";
import { useFetch } from "../../../utils/hooks";
import { API_ENDPOINTS } from "../../../utils/constants";
import { notify } from "../../../utils";
//import { getMembers } from "../../../redux/myGroup/actions";
import { getFeed } from "../../../redux/feed/actions";
import { useDispatch } from "react-redux";
// import { faTimes } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// import dayjs from 'dayjs';
//import relativeTime from 'dayjs/plugin/relativeTime';



const RenderComments = ({post, showComment}) => {

  // console.log("Post: ", post.comments)

  if (post.comments === undefined || post.comments.length === 0){

    return (
      <>
        {/* {postCards(comments)} */}
      </>
    );
  }
  // const comments = post.comments;

  const postCards = (comments, showComment) => {
      if (comments?.length !== 0){
        // console.log("comments: ", comments)
        return comments.map((comm) => (
          // <div className="post">{post.body}</div>
          // <PostCard  post={comm} />
          // {showModal ? <div /> : null}
          // <div className={`postcard-comment${showComment ? '' : '-noshow'}`} >
          //   {/* {comm.body} */}
          //             {/* <img alt="Inlo" src={chevl} className="feednot-nav-button-img"/*onClick={handleLogoClicked} *//> */}
          // </div>

          <>
                {/* <div className={`postcard-comment${showComment ? '' : '-noshow'}`}>
                  {comm.body}
                </div> */}
                <CommentCard className={`postcard-comment${showComment ? '' : '-noshow'}`}  post={comm} />
              </>
        
        )
      );
      }else return;
  };
  return (
    <>
      {postCards(post.comments, showComment)}
    </>
  );
};

function PostCard(props) {
  const { post, handleSelectMember, className, ...rest } = props;
  const { user, permissions, id: memberId, status } = post;
  const classes = classnames(
    `post-card__molecule`,
    status === "INACTIVE" ? "inactive-member" : "",
    className
  );
  const [showComment, setShowComment] = useState(false);
  const [postItems, setPostItems] = useState(post);
  const [postCommentItems, setPostCommentItems] = useState(post);

  const dispatch = useDispatch();

  const { post: createPostComment, post: updatePostLike } = useFetch();
  const [postCommentText, setPostCommentText] = useState('');
  const textareaRef = useRef(null);
  //const [postItems, setFeedItems] = useState(feed?.data?.results || []);

  useEffect(() => {
    setPostItems(post)
  }, [post]);

  // const handleDeleteMember = () => {
  //   deleteMember(API_ENDPOINTS.updatebyMemberID(memberId))
  //     .then((res) => {
  //       if (res.success === true) {
  //         notify.success("Member Deleted successfully", {
  //           toastId: "PermissionUpdateSuccess",
  //         });
  //         dispatch(getMembers());
  //       }
  //       if (res.success === false) {
  //         notify.error(res.message);
  //       }
  //     })
  //     .catch((error) => {
  //       notify.error(error.message);
  //     });
  // };

  // const handleChangePermissions = (permission, value) => {
  //   updateUserPermissions(API_ENDPOINTS.updatebyMemberID(memberId), {
  //     permissions: {
  //       [permission]: value,
  //     },
  //   })
  //     .then((res) => {
  //       if (res.success === true) {
  //         notify.success("Permission updated successfully", {
  //           toastId: "PermissionUpdateSuccess",
  //         });
  //         dispatch(getMembers());
  //       }
  //       if (res.success === false) {
  //         notify.error(res.message);
  //       }
  //     })
  //     .catch((error) => {
  //       notify.error(error.message);
  //     });
  // };

  // const renderPermissions = () => {
  //   return ["devices", "message", "location"].map((permission) => {
  //     return (
  //       <Input
  //         key={permission}
  //         variant={InputVariants.checkBox}
  //         label={permission}
  //         checked={permissions[permission]}
  //         onChange={() =>
  //           handleChangePermissions(permission, !permissions[permission])
  //         }
  //       />
  //     );
  //   });
  // };

  
  const handleSelect = () => {
    // setSelectedUser(member);

    // handleSelectMember(member)

    // viewMember(member);
  };

  const onCommentClick  = () => {
    if(showComment)
      setShowComment(false)
    else
      setShowComment(true)

  };

  const onLikeClick  = (post) => {
    let act = "like"

    if(post.likes.length > 0){
      if(post.likes[0].id === undefined){
        const isUserIdInList = post.likes.find((item) => item === localStorage.getItem("userID"))
        act = isUserIdInList ? "unlike" : "like";
      } else {
        const isUserIdInList = post.likes.find((item) => item.id === localStorage.getItem("userID"))
        act = isUserIdInList ? "unlike" : "like";
      }
    }

    updatePostLike(API_ENDPOINTS.updatePostLike(post.id), {
      action: act,
    })
          .then((res) => {
            if (res.success === true) {
              // notify.success("Like updated successfully", {
              //   toastId: "LikesUpdateSuccess",
              // });
              // dispatch(getFeed());
              res.data.user = postItems.user

              if(act === "unlike"){
              const filteredPostItems = postItems.likes.filter(postItem => {
                // Assuming you have an array of ids to exclude
                const excludedIds = localStorage.getItem("userID");
              
                // Return true for items whose id is not in the excludedIds array
                if(postItem.id === undefined){
                  return !excludedIds.includes(postItem);
                } else {
                  return !excludedIds.includes(postItem.id);
                }
              });
              res.data.likes = filteredPostItems
            }
              setPostItems(res.data)
            }
            if (res.success === false) {
              notify.error(res.message);
            }
          })
          .catch((error) => {
            notify.error(error.message);
          });
  };

  const onPostCommentClick  = (post) => {

    const textareaValue = textareaRef.current.value;
    console.log(textareaValue); // This will log the current text in the textarea

    if(textareaValue.length <= 0) return;

    createPostComment(API_ENDPOINTS.updatePostComment(post.id), {
      body: textareaValue
    })
          .then((res) => {
            if (res.success === true) {
              // notify.success("Like updated successfully", {
              //   toastId: "LikesUpdateSuccess",
              // });

              // console.log("res: ", res)

              res.data.user = postCommentItems.user;
              
              setPostCommentItems(res.data);
              setPostCommentText("")
            }
            if (res.success === false) {
              notify.error(res.message);
            }
          })
          .catch((error) => {
            notify.error(error.message);
          });
  };

  const handleTextAreaChange = (event) => {
    // console.log("Post: ", post)
    setPostCommentText(event.target.value);
  };

const now = new Date().getTime();
const postTime = new Date(post.createdAt).getTime();
const timeDiff = now - postTime;

const seconds = Math.floor(timeDiff / 1000);
const minutes = Math.floor(seconds / 60);
const hours = Math.floor(minutes / 60);   

const days = Math.floor(hours / 24);

let formattedTime;

if (days === 0) {
   if (hours === 0) {
     if (minutes === 0) {
       formattedTime = "Just now";
     } else {
       formattedTime = `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
     }
   } else {
     formattedTime = `${hours} hour${hours > 1 ? 's' : ''} ago`;
   }
 } else if (days <= 5) {
   formattedTime = `${days} day${days > 1 ? 's' : ''} ago`;
 } else {
   const date = new Date(postItems.createdAt);
   const month = date.toLocaleString('default', { month: 'short' });
   const day = date.getDate();
   const year = date.getFullYear();
   formattedTime = `${month} ${day}, ${year}`;
 }

//  console.log("Post: ", post)
  return (
    <>
      <div className={classes} {...rest}>
        <div className="post-profile-primary-container">
          <img
            className="post-profile-picture"
            src={postItems.user.profilePicture}
            alt={`${postItems.user.firstName}'s image`}
            // onClick={handleSelect} 
          />
          <div className="post-profile-body-container">
            <div className="post-profile-body">
              {postItems.body}
            </div>
            <div className="post-profile-date">
            {"@"}{postItems.user.username}{"  •  "}{formattedTime}
            </div>
          </div>
        </div>
        <div className="post-profile-count-container">
          <div className="post-profile-comment">
            comments
          </div>
          <div className="post-profile-comment-count">
            {post.comments ? post.comments.length : ""}
          </div>
          <div className="post-profile-like">
            likes
          </div>
          <div className="post-profile-like-count">
            {postItems.likes.length}
          </div>
        </div>

        <div className="post-profile-btn-container">
          <button className="post-profile-comment-btn"
            onClick={() => onCommentClick()}>
            Comment
          </button>
          <button className="post-profile-like-btn"
            onClick={() => onLikeClick(postItems)}>
            Like
          </button>
        </div>
        <div className="post-comment-container">
          <div className={`post-post${showComment ? '' : '-noshow'}`}>
            {/* <textarea className="post-post-textarea" placeholder='Enter text...'></textarea> */}
            <textarea
              className="post-post-textarea"
              placeholder="Enter text..."
              value={postCommentText}
              onChange={handleTextAreaChange}
              ref={textareaRef}
            />
            <button className="post-post-btn"
              onClick={() => onPostCommentClick(postCommentItems)}>Comment</button>
          </div>
          <RenderComments post={postCommentItems} showComment={showComment}/>
        </div>
      </div>
    </>
  );
}

export default PostCard;
