import React from "react";
import classnames from "classnames";
import { TextAtom, TextColors, TextVariants, TextWeights } from "../../atoms";
import "./DeviceCard.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBroadcastTower,
  faMobileAlt,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
//import  EditAvatar from "../../pages/devices/components/EditAvatar"
import { DATETIME_FORMAT, composeDateUsingTimestamp } from "../../../utils";
import { ROUTES } from "../../../utils/constants";
import { useHistory } from "react-router";

function DeviceCard(props) {
  const { siteID, nodeID, type, name, discoveredAt, lastUpdatedAt, ...rest } = props;

  const history = useHistory();
  const classes = classnames("device_card");

  const handleOnEditClick = () => {
    console.log("handleOnEditClick")
    // history.push(ROUTES.nodeDetails(siteID, nodeID));
    history.push(ROUTES.profileDeviceDetails(nodeID));
  }

  const renderIconBasedOnDeviceType = () => {

    switch (type) {
      case "virtual_satellite":
        return (
          <FontAwesomeIcon
            icon={faBroadcastTower}
            className="device-card_icon station"
          />
        );
      case "base_station":
        return (
          <FontAwesomeIcon
            icon={faBroadcastTower}
            className="device-card_icon station"
          />
        );
      case "object":
        return (
          <FontAwesomeIcon icon={faMobileAlt} 
                            //icon={faEdit}
                            className="device-card_icon object"
                            onClick={handleOnEditClick} />
        );
      default: 
        return (
          <FontAwesomeIcon icon={faMobileAlt} 
                            //icon={faEdit}
                            className="device-card_icon object"
                            onClick={handleOnEditClick} />
        );
      // return (
      //   <FontAwesomeIcon
      //     //icon={faBroadcastTower}
      //     className="device-card_icon"
      //     onClick={handleOnEditClick} icon={faEdit} 
      //   />
      // );
    } 
  };

  // details.push({
  //   label: "img",
  //   value: (
  //     <img
  //       className="account_page-profile_pic"
  //       src={avtar}
  //       alt="user profile pic"
  //     />
  //   ),
  //   editComponent: EditAvatar,
  //    //editComponent: EditProfilePic,
  // });

  return (
    <div className={classes} {...rest}>
      {renderIconBasedOnDeviceType()}
      <div className="device-card_display-texts">
        <TextAtom
          className="device-card__molecule-text-element_name"
          variant={TextVariants.small}
          weight={TextWeights.bold}
          color={TextColors.primaryGrey}
        >
          {type?.toUpperCase()}
        </TextAtom>
        <TextAtom
          className="device-card__molecule-text-element_name"
          variant={TextVariants.small}
          weight={TextWeights.bold}
          color={TextColors.primaryGrey}
        >
          Name: {name?.toUpperCase()}
        </TextAtom>
        <TextAtom
          className="device-card__molecule-text-element"
          variant={TextVariants.small}
          weight={TextWeights.semiBold}
          color={TextColors.primaryGrey}
        >
          Discovered at :{" "}
          {composeDateUsingTimestamp(discoveredAt, DATETIME_FORMAT.dateAndTime)}
        </TextAtom>
        <TextAtom
          className="device-card__molecule-text-element"
          variant={TextVariants.small}
          weight={TextWeights.semiBold}
          color={TextColors.primaryGrey}
        >
          Last Updated :{" "}
          {composeDateUsingTimestamp(
            lastUpdatedAt,
            DATETIME_FORMAT.dateAndTime
          )}
        </TextAtom>
      </div>
    </div>
  );
}

export default DeviceCard;
