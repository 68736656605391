import React from "react";
import { Route, Switch, useRouteMatch } from "react-router";
import CreateSite from "./components/create-site/container";
import DisplaySites from "./components/display-sites/container";
import SiteDetails from "./components/site-details/container";

function SitesComponent(props) {
  const { msgProfile } = props
  const { path } = useRouteMatch();

  console.log("Path: " ,path)

  const renderChildRoutes = () => (
    <Switch>
      <Route exact path={path}>
        <DisplaySites />
      </Route>
      <Route path={`${path}/create`}>
        <CreateSite />
      </Route>
      <Route path={`${path}/:siteId`}>
        <SiteDetails />
      </Route>
    </Switch>
  );

  return (
    renderChildRoutes()
  );
}

export default SitesComponent;
