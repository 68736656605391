import React, { useEffect, useState, useCallback } from "react";
import "./CommentCard.scss";
import classnames from "classnames";
import {
  Input,
  InputVariants,
  TextAtom,
  TextSizes,
  TextVariants,
  TextWeights,
} from "../../atoms";
import { useFetch } from "../../../utils/hooks";
// import { API_ENDPOINTS } from "../../../utils/constants";
// import { notify } from "../../../utils";
//import { getMembers } from "../../../redux/myGroup/actions";
import { useDispatch } from "react-redux";
import defaultImage from "../../../assets/images/default-profile-icon.png"
// import { faTimes } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { API_ENDPOINTS } from "../../../utils/constants";
import { notify } from "../../../utils";

// import dayjs from 'dayjs';
//import relativeTime from 'dayjs/plugin/relativeTime';



// const RenderComments = ({post, showComment}) => {

//   // console.log("Post: ", post.comments)

//   if (post.comments === undefined || post.comments.length === 0){

//     return (
//       <>
//         {/* {postCards(comments)} */}
//       </>
//     );
//   }
//   // const comments = post.comments;

//   const postCards = (comments, showComment) => {
//       if (comments?.length !== 0){
//         // console.log("comments: ", comments)
//         return comments.map((comm) => (
//           // <div className="post">{post.body}</div>
//           // <PostCard  post={comm} />
//           // {showModal ? <div /> : null}
//           // <div className={`postcard-comment${showComment ? '' : '-noshow'}`} >
//           //   {/* {comm.body} */}
//           //             {/* <img alt="Inlo" src={chevl} className="feednot-nav-button-img"/*onClick={handleLogoClicked} *//> */}
//           // </div>

//           <>
//                 {/* <div className={`postcard-comment${showComment ? '' : '-noshow'}`}>
//                   {comm.body}
//                 </div> */}
//                 <PostCard className={`postcard-comment${showComment ? '' : '-noshow'}`}  post={comm} />
//               </>
        
//         )
//       );
//       }else return;
//   };
//   return (
//     <>
//       {commentCards(post.comments, showComment)}
//     </>
//   );
// };

function CommentCard(props) {
  const { post, handleSelectMember, className, ...rest } = props;
  const { user, permissions, id: memberId, status } = post;
  const classes = classnames(
    `comment-card__molecule`,
    status === "INACTIVE" ? "inactive-member" : "",
    className
  );
  const [showComment, setShowComment] = useState(false);
  const [postItems, setPostItems] = useState(post);

  const dispatch = useDispatch();

  const { post: updatePostCommentLike, del: deleteMember } = useFetch();

  // const handleDeleteMember = () => {
  //   deleteMember(API_ENDPOINTS.updatebyMemberID(memberId))
  //     .then((res) => {
  //       if (res.success === true) {
  //         notify.success("Member Deleted successfully", {
  //           toastId: "PermissionUpdateSuccess",
  //         });
  //         dispatch(getMembers());
  //       }
  //       if (res.success === false) {
  //         notify.error(res.message);
  //       }
  //     })
  //     .catch((error) => {
  //       notify.error(error.message);
  //     });
  // };

  // const handleChangePermissions = (permission, value) => {
  //   updateUserPermissions(API_ENDPOINTS.updatebyMemberID(memberId), {
  //     permissions: {
  //       [permission]: value,
  //     },
  //   })
  //     .then((res) => {
  //       if (res.success === true) {
  //         notify.success("Permission updated successfully", {
  //           toastId: "PermissionUpdateSuccess",
  //         });
  //         dispatch(getMembers());
  //       }
  //       if (res.success === false) {
  //         notify.error(res.message);
  //       }
  //     })
  //     .catch((error) => {
  //       notify.error(error.message);
  //     });
  // };

  // const renderPermissions = () => {
  //   return ["devices", "message", "location"].map((permission) => {
  //     return (
  //       <Input
  //         key={permission}
  //         variant={InputVariants.checkBox}
  //         label={permission}
  //         checked={permissions[permission]}
  //         onChange={() =>
  //           handleChangePermissions(permission, !permissions[permission])
  //         }
  //       />
  //     );
  //   });
  // };

  
  const handleSelect = () => {
    // setSelectedUser(member);

    // handleSelectMember(member)

    // viewMember(member);
  };

  const onCommentClick  = () => {
    console.log("onCommentClick")
    if(showComment)
      setShowComment(false)
    else
      setShowComment(true)

  };

//   dayjs.extend(relativeTime);

// // ...

// const formattedTimer = dayjs(post.createdAt).fromNow();


  const now = new Date().getTime();
const postTime = new Date(post.createdAt).getTime();
const timeDiff = now - postTime;

const seconds = Math.floor(timeDiff / 1000);
const minutes = Math.floor(seconds / 60);
const hours = Math.floor(minutes / 60);   

const days = Math.floor(hours / 24);

let formattedTime;

if (days === 0) {
   if (hours === 0) {
     if (minutes === 0) {
       formattedTime = "Just now";
     } else {
       formattedTime = `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
     }
   } else {
     formattedTime = `${hours} hour${hours > 1 ? 's' : ''} ago`;
   }
 } else if (days <= 5) {
   formattedTime = `${days} day${days > 1 ? 's' : ''} ago`;
 } else {
   const date = new Date(post.createdAt);
   const month = date.toLocaleString('default', { month: 'short' });
   const day = date.getDate();
   const year = date.getFullYear();
   formattedTime = `${month} ${day}, ${year}`;
 }

 const onLikeClick  = (post) => {
  let act = "like"

  if(post.likes.length > 0){
    if(post.likes[0].id === undefined){
      const isUserIdInList = post.likes.find((item) => item === localStorage.getItem("userID"))
      act = isUserIdInList ? "unlike" : "like";
    } else {
      const isUserIdInList = post.likes.find((item) => item.id === localStorage.getItem("userID"))
      act = isUserIdInList ? "unlike" : "like";
    }
  }

  updatePostCommentLike(API_ENDPOINTS.updateCommentLike(post.id), {
    action: act,
  })
        .then((res) => {
          if (res.success === true) {
            // notify.success("Like updated successfully", {
            //   toastId: "LikesUpdateSuccess",
            // });
            // dispatch(getFeed());
            res.data.user = postItems.user

            if(act === "unlike"){
            const filteredPostItems = postItems.likes.filter(postItem => {
              // Assuming you have an array of ids to exclude
              const excludedIds = localStorage.getItem("userID");
            
              // Return true for items whose id is not in the excludedIds array
              if(postItem.id === undefined){
                return !excludedIds.includes(postItem);
              } else {
                return !excludedIds.includes(postItem.id);
              }
            });
            res.data.likes = filteredPostItems
          }
            setPostItems(res.data)
          }
          if (res.success === false) {
            notify.error(res.message);
          }
        })
        .catch((error) => {
          notify.error(error.message);
        });
};

//  console.log("Comment: ", post)
  return (
    <>
      <div className={classes} {...rest}>
        <div className="comment-profile-primary-container">
          <img
            className="comment-profile-picture"
            src={post.user.profilePicture !== undefined ? post.user.profilePicture : defaultImage}
            alt={`${post.user.firstName}'s image`}
            // onClick={handleSelect} 
          />
          <div className="comment-profile-body-container">
            <div className="comment-profile-body">
              {post.body}
            </div>
            <div className="comment-profile-date">
              {"@"}{post.user.username}{"  •  "}{formattedTime}
            </div>
          </div>
        </div>
        <div className="comment-profile-count-container">
          {/* <div className="comment-profile-comment">
            comments
          </div>
          <div className="comment-profile-comment-count">
            {post.comments ? post.comments.length : ""}
          </div> */}
          <div className="comment-profile-like">
            likes
          </div>
          <div className="comment-profile-like-count">
            {postItems.likes.length}
          </div>
        </div>

        <div className="comment-profile-btn-container">
          {/* <button className="comment-profile-comment-btn"
            onClick={() => onCommentClick()}>
            Comment
          </button> */}
          <button className="comment-profile-like-btn"
            onClick={() => onLikeClick(postItems)}>
            Like
          </button>
        </div>
        {/* {renderComments()} */}
        {/* <RenderComments post={post} showComment={showComment}/> */}
      </div>
    </>
  );
}

export default CommentCard;
