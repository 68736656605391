import React from "react";
import { TextAtom, TextVariants } from "../../atoms";
import classnames from "classnames";
import PropTypes from "prop-types";
import "./InteriorPageHeadingWithMenu.scss";

function InteriorPageHeadingWithMenu(props) {
  const { spanName, heading, className, children, ...rest } = props;
  const classes = classnames(
    `interior-page-heading-with-menu__molecule`,
    className
  );
  return (
    <div className={classes} {...rest}>
      <TextAtom variant={TextVariants.headingOne}>{heading}</TextAtom>
      <span className={spanName}>{children}</span>
    </div>
  );
}

export default InteriorPageHeadingWithMenu;

InteriorPageHeadingWithMenu.propTypes = {
  /**
   * The Page heading
   */
  heading: PropTypes.string,
};

InteriorPageHeadingWithMenu.defaultProps = {
  heading: "",
};
