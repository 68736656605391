import { makeRequest, StateActions } from "../../utils";
import { ADD_NOTIFICATIONS } from "./types";
import { API_ENDPOINTS } from "../../utils/constants";

export const getNotifications = () => async (dispatch) => {
  const action = new StateActions(ADD_NOTIFICATIONS);
  const limit = 100;

  const par = {params:{limit}}

  dispatch(action.loading);
  try {
    const response = await makeRequest.get(API_ENDPOINTS.notifications,null, null, {
      limit: 10
    } );

    if (response.success === false)  dispatch(action.error(response));

    if (response.success === true) dispatch(action.loaded(response));

  } catch (error) {
    console.error("boooo:", error);
    dispatch(action.error(error));
  }
};
