import React from "react";
import { useSelector } from "react-redux";
import DisplayDevicesComponent from "./component";

function DisplayDevices() {
  // const devices = useSelector((state) => state.nodes);
  const devices = useSelector((state) => state.devices);
  // const locations = useSelector((state) => state.locations);
  // const sites = useSelector((state) => state.sites);

  return <DisplayDevicesComponent /*sites={sites}*/ devices={devices} />;
}

export default DisplayDevices;