import { useWindowWidth } from "@react-hook/window-size";
import { REQ_STATE } from "../../../../../utils";
import { ROUTES } from "../../../../../utils/constants";
import { useHistory } from "react-router";
import { SCREEN_SIZE } from "../../../../../utils/layout";
import {
  Error,
  Link,
  Loading,
  Map,
  TextAtom,
  TextVariants,
  MapVariants,
  Input,
  InputVariants,
} from "../../../../atoms";
import {
  faLaptopHouse,
  faBell,
  faUserCircle,
  faSignOutAlt,
  faMapMarkerAlt,
  faUser,
  faTablet,
} from "@fortawesome/free-solid-svg-icons";
import { ObjectSlide, SiteList, MemberSlide } from "../../../../molecules";
import { SaveDeviceModal, FeedNotModal } from "../../../../organisms";
import "./DisplayOutdoor.scss";
import { useSelector } from "react-redux";
import React, { useEffect, useState, useCallback } from "react";
import { useGeoLocation } from "../../../../../utils/hooks";
import L, { Browser } from "leaflet";
import "leaflet/dist/leaflet.css";
import { useEvents } from "../../../../../utils/hooks/useEvents";
import { useEventChat } from "../../../../../utils/hooks/useEventChat";
import UserInfo from "../../../profile/user-management/components/UserInfo";
import { API_ENDPOINTS } from "../../../../../utils/constants";
import {useDebouncer, useFetch } from "../../../../../utils/hooks";
import { notify } from "../../../../../utils";
import { useDispatch } from "react-redux";
import { DropDownUserResults } from "../../../../molecules";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import chevr from "../../../../../assets/images/chevron-right.png";
import chevl from "../../../../../assets/images/chevron-left.png";

import mem  from "../../../../../assets/images/default-profile-icon.png"
import img  from "../../../../../assets/images/marker.png"
import obj  from "../../../../../assets/images/object-icon.jpg"
// import House from "../../../assets/images/default-profile-icon.png";

const zoom = 18

const markerIcon = new L.Icon({
  iconUrl: require("../../../../../assets/images/marker.png"),
  iconSize: [40, 40],
  iconAnchor: [17, 46], //[left/right, top/bottom]
  popupAnchor: [0, -46], //[left/right, top/bottom]
});

function RenderOutdoorData({ map, nodes }) {
  const nodeLocations = nodes.data;

  const NodeLocationCards = () => {
    switch (nodes.state) {
      case REQ_STATE.initialized:
      case REQ_STATE.loading:
        return <Loading />;
      case REQ_STATE.error:
        return <Error />;
      case REQ_STATE.loaded: {
        if (nodeLocations?.length !== 0){
          return nodeLocations.map((node) => (
            <ObjectSlide key={node._id} node={node} map={map} className="card" />
          ));
        }else return;
      }
    }
  };

  return (
    <>
      {NodeLocationCards()}
    </>
  );
};

function RenderMemberData({ map, mems, folls, locs }) {
  const memLocations = mems.data.results;
  const follLocations = folls.data.results;
  const allLocations = locs.data;

  const findOverlapAndUpdate = () => {
    const matchedLocations = memLocations.map(mem => {
      const matchingFol = follLocations.find(fol => fol.groupOwner.id === mem.user.id);
      mem.both = !!matchingFol; // Efficiently set both to true or false
      return mem; // Return the updated mem object
    });

    const folLocations = follLocations.map(fol => {
      const matchingMem = memLocations.find(mem => mem.user.id === fol.groupOwner.id);
      fol.both = !!matchingMem; // Efficiently set both to true or false
      return fol; // Return the updated mem object
    });

  };

  const updateLocation = () => {
    const updatedLocations = memLocations.map(mem => {
      const updateMem = allLocations.find(loc => loc._id === mem.user.id);
      if(!(updateMem === undefined)){
        mem.lat = updateMem.lastLocation.lat;
        mem.long = updateMem.lastLocation.long;
      }
      return mem; // Return the updated mem object
    });

    const folLocations = follLocations.map(fol => {
      const updateFol = allLocations.find(loc => loc._id === fol.groupOwner.id);
      if(!(updateFol === undefined)){
        fol.lat = updateFol.lastLocation.lat;
        fol.long = updateFol.lastLocation.long;
      }
      return fol; // Return the updated mem object
    });

  };

  // findOverlapAndUpdate();
  // updateLocation();

  const MemLocationCards = () => {
      if (memLocations?.length !== 0){
        return memLocations.map((mem) => (
          <MemberSlide lat={mem.lat} long={mem.long} key={mem._id} member={mem} map={map} className="card" />
        ));
      }else return;
  };
  const FolLocationCards = () => {

    const filteredFolLocations = follLocations.filter(mem => {
      return /*!mem.both && */!memLocations.find(fol => fol.user.id === mem.groupOwner.id);
    });
    
    if (filteredFolLocations?.length !== 0){
      return filteredFolLocations/*.filter(mem => !mem.both)*/.map((mem) => (
        <MemberSlide key={mem._id} member={mem} map={map} className="card" />
      ));
    }else return;
};
  return (
    <>
      {MemLocationCards()}
      {FolLocationCards()}
    </>
  );
};

// const noSites = () => {
//   return (
//     <TextAtom variant={TextVariants.regular}>
//       Looks like you havent added sites yet! Feel free to{" "}
//       <Link to={ROUTES.createSite}> add a site</Link>
//     </TextAtom>
//   );
// };

function RenderLocationCards(props) {
  const { map, sites, userid, updateSiteID} = props

  const ownerSiteLocationCards = () => {
    // if (sites.data.count === 0 && sites.data.count === 0) return noSites();
    const siteLocations = sites.data.sites;

    const ownerSiteLocationCardsData = siteLocations.filter(
      (site) => site.owner === userid
    );

    if (ownerSiteLocationCardsData?.length !== 0)
      return ownerSiteLocationCardsData.map((site) => (
        <SiteList key={site.siteID} site={site} image={site.picture} map={map} updateSiteID={updateSiteID} className="card" />
      ));
    else return;
  };

  const notOwnedSiteLocationCards = () => {
    const siteLocations = sites.data.sites;

    const notOwnedSiteLocationCardsData = siteLocations.filter(
      (site) => site.owner !== userid
    );

    if (notOwnedSiteLocationCardsData?.length !== 0)
      return notOwnedSiteLocationCardsData.map((site) => (
        <SiteList key={site.siteID} site={site} image={site.picture} map={map} updateSiteID={updateSiteID} className="card" />
      ));
    else return;
  };

  switch (sites.state) {
    case REQ_STATE.initialized:
    case REQ_STATE.loading:
      return <Loading />;
    case REQ_STATE.error:
      return <Error />;
    case REQ_STATE.loaded: {
      return (
        <>
          {ownerSiteLocationCards()}
          {notOwnedSiteLocationCards()}
        </>
      );;
    }
  }
}

function DisplayOutdoorComponent(props) {
  const { notifications, 
          feed, 
          updateCheck, 
          devices, 
          getFingerprint, 
          sites, 
          members, 
          following, 
          memLocations, 
          nodes, 
          updateSiteID, 
          getSiteID } = props;
  const width = useWindowWidth();
  const { post } = useFetch();
  const userIDLoggedIn = useSelector((state) => state.user?.data?.userID);
  const location = useGeoLocation();
  const [position, setPosition] = useState([85, 85]);
  const [map, setMap] = useState(null);
  const locations = [];
  const [showModal, setShowModal] = useState(false);
  const [showFeednot, setShowFeedNot] = useState(true);
  const [showSiteList, setShowSiteList ] = useState(true)
  const [showMemberList, setShowMemberList ] = useState(false)
  const [showDeviceList, setShowDeviceList ] = useState(false)

  const [usernameOrEmail, setUsernameOrEmail] = useState("");

 // const [selectedUser, setSelectedUser] = useState();
  const { get: getMatchingUsers } = useFetch();
  const [matchingUsers, setMatchingUsers] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const fnshow = showFeednot;

  // Websocket Stuff!!
  //useEventChat();
  
  const findMatchingUsernames = (usernameOrEmail) => {
    if (usernameOrEmail !== "") {
      getMatchingUsers(API_ENDPOINTS.searchUser(usernameOrEmail))
        .then((res) => {
          if (res.success === true) setMatchingUsers(res.data);
          if (res.success === false) setMatchingUsers([]);
        })
        .catch((error) => notify.error(error.message));
    }
  };

  useDebouncer(() => findMatchingUsernames(usernameOrEmail), 500, [
    usernameOrEmail,
  ]);

  const handleSelectUser = (user) => {
    setUsernameOrEmail(user.username);
   // setSelectedUser(user);
    viewUser(user);
  };

  const viewUser = (user) => {
    history.push(ROUTES.userDetails(user.username))
  }

  const renderMatchingUsers = () => {
    if (matchingUsers.length === 0) return;
    
    return matchingUsers.map((user) => (
      <DropDownUserResults
        key={user.username}
        user={user}
        profilePicture={user.profilePicture}
        username={user.username}
        name={`${user.firstName} ${user.lastName}`}
        onClick={() => handleSelectUser(user)}
      />
    ));
  };

  // const handleOutdoorData = () => {
  //   // eslint-disable-next-line default-case
  //   // switch (sites.state) {
  //   //   case REQ_STATE.initialized:
  //   //   case REQ_STATE.loading:
  //   //     return <Loading />;
  //   //   case REQ_STATE.error:
  //   //     return <Error />;
  //   //   case REQ_STATE.loaded:
  //       return renderData();
  //   // }
  // };

  function getModalState(){
    return showModal;
  }

  const extractSiteData = (results) => {
    results.forEach((location) => {
      const { picture, user, name, long, lat, id } = location;
      const locationData = {
        name,
        longLat: [lat, long],
        image: picture,
        type: "site"
      };
      locations.push(locationData);
      // if(getSiteID() === "")
      //   updateSiteID(siteID)
    });
    return locations;
  };


  const findOverlapAndUpdate = () => {
    const mems = members.data.results;
    const folls = following.data.results;
    const allLocations = memLocations.data;

    const matchedLocations = mems.map(mem => {
      const matchingFol = folls.find(fol => fol.groupOwner.id === mem.user.id);
      mem.both = !!matchingFol; // Efficiently set both to true or false
      return mem; // Return the updated mem object
    });

    const folLocations = folls.map(fol => {
      const matchingMem = mems.find(mem => mem.user.id === fol.groupOwner.id);
      fol.both = !!matchingMem; // Efficiently set both to true or false
      return fol; // Return the updated mem object
    });

  };

  const updateLocation = () => {
    const mems = members.data.results;
    const folls = following.data.results;
    const allLocations = memLocations.data;

    const updatedLocations = mems.map(mem => {
      const updateMem = allLocations.find(loc => loc._id === mem.user.id);
      if(!(updateMem === undefined)){
        mem.lat = updateMem.lastLocation.lat;
        mem.long = updateMem.lastLocation.long;
      }
      return mem; // Return the updated mem object
    });

    const folLocations = folls.map(fol => {
      const updateFol = allLocations.find(loc => loc._id === fol.groupOwner.id);
      if(!(updateFol === undefined)){
        fol.lat = updateFol.lastLocation.lat;
        fol.long = updateFol.lastLocation.long;
      }
      return fol; // Return the updated mem object
    });

    updatedLocations.forEach((location) => {
      const { picture, user, name, long, lat, id } = location;
      if (lat !== undefined && long !== undefined) {
        const locationData = {
          name,
          longLat: [lat, long],
          image: location.user.profilePicture,
        };
        locations.push(locationData);
      }
    });

    folLocations.forEach((location) => {
      const { picture, user, name, long, lat, id } = location;
      if (lat !== undefined && long !== undefined) {
        const locationData = {
          name: location.groupOwner.username,
          longLat: [lat, long],
          image: location.groupOwner.profilePicture,
          type: "people"
        };
        locations.push(locationData);
      }
    });


  };

   // instance of websocket connection as a class property
  //  const ws = new WebSocket('ws://localhost:3000/ws')

  //  const componentDidMount = () => {
  //      this.ws.onopen = () => {
  //      // on connecting, do nothing but log it to the console
  //      console.log('connected')
  //      }
  //      this.ws.onmessage = evt => {
  //      // listen to data sent from the websocket server
  //      const message = JSON.parse(evt.data)
  //      this.setState({dataFromServer: message})
  //      console.log(message)
  //      }
  //      this.ws.onclose = () => {
  //      console.log('disconnected')
  //      // automatically try to reconnect on connection loss
  //      }
  //  //}
  // //  render(
  // //      <ChildComponent websocket={this.ws} />
  // //  );
  // }
  
const deviceChecker = () => {
  updateCheck(true)
  const deviceID = "device" + localStorage.getItem("userID")
  //console.log("checking devices", localStorage.getItem("deviceID"))
  if(localStorage.getItem(deviceID) === null){//not device id generated yet
    console.log("checking devices null")
      localStorage.setItem(deviceID, Math.round(Math.random()*1000000000000));
      openModal()
  }else {//already has deviceID generated
    console.log("checking devices", devices[0])
    for(var i = 0; i<Object.keys(devices).length-1;i++){
      if(devices[i].fingerprint === localStorage.getItem(deviceID)){
        localStorage.setItem("id", devices[i].id)
        updateCheck("true")
        return
      }
    }
    openModal()
  }
}

const openModal = () => {
  setShowModal(true);
};

const handleSaveDevice = () => {
  console.log("handle saved device")
  return (
      <SaveDeviceModal  setShowModal={setShowModal}/>
  );
};

const handleOpenModal = () => {
  setShowModal(true);
};

const handleCloseModal = () => {
  setShowModal(false);
};

const handleFeedNotBtn = () => {
  setShowFeedNot(true);
};

const handleFeedNoCloseBtn = () => {
  setShowFeedNot(false);
};

const handleDeviceBtn = () => {
  setShowSiteList(false)
  setShowMemberList(false)
  setShowDeviceList(true)
};

const handleSiteBtn = () => {
  setShowSiteList(true)
  setShowMemberList(false)
  setShowDeviceList(false)
};

const handleMemberBtn = () => {
  setShowSiteList(false)
  setShowMemberList(true)
  setShowDeviceList(false)
};

const handleFineMeBtn = useCallback(() => {
    map.setView([location.coordinates.lat, location.coordinates.lng], zoom);
}, [map]);

const handleOnChange = function (e) {
  const { value } = e.target;
  setUsernameOrEmail(value);
};

  const renderData = () => {
    
    const viewPosition = {
      name: "You",
      longLat: [location.coordinates.lat, location.coordinates.lng],
      image: require("../../../../../assets/images/marker.png")
    };
    locations.push(viewPosition);

    if(localStorage.getItem(localStorage.getItem("userID")) === null
        && !getModalState()){

      deviceChecker()

    } else if (""+localStorage.getItem(localStorage.getItem("userID") === true)){

      for(var i = 0; i<Object.keys(devices).length-1;i++){
        if(devices[i].fingerprint === localStorage.getItem("deviceID")){
          localStorage.setItem("id", devices[i].id)
          updateCheck("true")
        }
      }

    } else if (localStorage.getItem(localStorage.getItem("userID") === false)){

      updateCheck("false")

    }

    if(sites.state === "loaded")
      extractSiteData(sites.data.sites)

    if(members.state === "loaded" && following.state === "loaded")
      findOverlapAndUpdate();

    if(members.state === "loaded" && following.state === "loaded" && memLocations.state === "loaded")
      updateLocation();

    return (
      <>
        { showDeviceList
        ? <div className="object-slide-container">
          <div className="object-slide">
            {map ? <RenderOutdoorData 
                    map={map} 
                    nodes = {nodes}/> : null}
          </div>
        </div> : null }
        { showSiteList 
        ? <div className="site-list-container">
          <div className="site-list" >
            {map ? <RenderLocationCards 
                    map={map} 
                    sites = {sites} 
                    userid = {userIDLoggedIn}
                    updateSiteID={updateSiteID}/> : null}
            </div>
        </div> : null }
        { showMemberList 
        ? <div className="site-list-container">
          <div className="site-list" >
            {map ? <RenderMemberData 
                    map={map} 
                    mems = {members}
                    locs ={memLocations}
                    folls = {following} 
                    userid = {userIDLoggedIn}
                    updateSiteID={updateSiteID}/> : null}
            </div>
        </div> : null }

        <div className="list-nav-container">
          <button className="list-nav-button"
            onClick={handleDeviceBtn} >
              <FontAwesomeIcon icon={faTablet} className="list-nav-button-img" />
          </button>
          <button className="list-nav-button"
            onClick={handleSiteBtn} >
              <FontAwesomeIcon icon={faMapMarkerAlt} className="list-nav-button-img" />
          </button>
          <button className="list-nav-button" 
          onClick={handleMemberBtn} >
            <FontAwesomeIcon icon={faUser} className="list-nav-button-img" />
          </button>
        </div>

        <div className={`feednot-nav-container${fnshow ? '-show' : ''}`} onClick={handleFeedNotBtn}>
              <img alt="Inlo" src={chevr} className="feednot-nav-button-img"/*onClick={handleLogoClicked}*/ />
        </div>

        <div className={`feednot-nav-container${fnshow ? '' : '-show'}`} onClick={handleFeedNoCloseBtn}>
              <img alt="Inlo" src={chevl} className="feednot-nav-button-img"/*onClick={handleLogoClicked} *//>
        </div>


        <FeedNotModal show={showFeednot} notifications={notifications} feed={feed}/>

        <div className="top_spacer"></div>
        <div className="searchBarandResults_wrapperr">
          <Input
            label="Search"
            value={usernameOrEmail}
            onChange={(e) => handleOnChange(e)}
            variant={InputVariants.text}
          />
          {usernameOrEmail.trim() && (
            <div className="search-results-dropdown">
              {renderMatchingUsers()}
            </div>
          )}
        </div>

        <div className="find-me-btn" onClick={handleFineMeBtn}></div>

        {fnshow ?  <Map
          zoom={zoom}
          whenCreated={setMap}
          variant={MapVariants.withMaker}
          className="map-feednot"
          locations
          locationData={locations}
          nodes={nodes}
          feednot={fnshow}
          // sites={sites}
          scrollWheelZoom={SCREEN_SIZE.desktop(width)}>
        </Map> :  null}

        {fnshow ?  null :  <Map
          zoom={zoom}
          whenCreated={setMap}
          variant={MapVariants.withMaker}
          className="map"
          locations
          locationData={locations}
          nodes={nodes}
          feednot={fnshow}
          // sites={sites}
          scrollWheelZoom={SCREEN_SIZE.desktop(width)}>
        </Map>}
         {showModal ? <SaveDeviceModal setShowModal={setShowModal} updateCheck={updateCheck}/> : null}

      </>
    );
  }; 
  
  useEffect(() => {
    if (location.coordinates === undefined) 
      return null;
    else if (location.coordinates.lat !== "" && location.coordinates.lng !== "" ) 
      setPosition([location.coordinates.lat, location.coordinates.lng]);
    else 
      return null;
  }, [location]);

  return renderData();
}

export default DisplayOutdoorComponent;
