import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { getNodes } from "../../../redux/nodes/actions";
import { getSites } from "../../../../redux/sites/actions";
import { getLocations } from "../../../../redux/locations/actions";
import DevicesComponent from "./component";

function Devices() {
  const dispatch = useDispatch();
  const msgProfile = useSelector((state) => state.msgProfile);
  
  useEffect(() => {
    // dispatch(getNodes());
    dispatch(getLocations());
    dispatch(getSites());
  }, [dispatch]);

  // const devices = useSelector(state => state.nodes);
  const devices = useSelector((state) => state.devices);
  const sites = useSelector(state => state.sites);

  return <DevicesComponent msgProfile={msgProfile} sites={sites} devices={devices} />;
}

export default Devices;
