import { makeRequest, StateActions } from "../../utils";
import { ADD_LOCATIONS, UPDATE_LOCATIONS } from "./types";
import { API_ENDPOINTS } from "../../utils/constants";

export const getLocations = () => async (dispatch) => {
  const action = new StateActions(ADD_LOCATIONS);
  dispatch(action.loading);
  try {
    const response = await makeRequest.get(API_ENDPOINTS.deviceLocations);
    if (response.success === false){ 
      // console.log("locations failed", response)
      dispatch(action.error(response));
    }
    if (response.success === true){ 
      // console.log("locations response", response)
      dispatch(action.loaded(response))
    };
  } catch (error) {
    // console.log("locations error", error)
    console.error(error);
    dispatch(action.error(error));
  }
};

// export const updateLocation = (viewPosition, id) => async (dispatch) => {
//   const action = new StateActions(UPDATE_LOCATIONS);
//   dispatch(action.loading);
//   try {
//     const response = await makeRequest.post(API_ENDPOINTS.locations);
//     if (response.success === false) dispatch(action.error(response));
//     if (response.sucess === true){ 
//       console.log("devices response", response)
//       dispatch(action.loaded(response))
//     };
//   } catch (error) {
//     console.error(error);
//     dispatch(action.error(error));
//   }
// };

