import React from "react";
import NotificationsBarAcceptReject from "./components/notifications-bar-accept-reject/container";
import NotificationsBarOnlyText from "./components/notifications-bar-only-text/container";
import NotificationBarWithResendAndCancel from "./components/notifications-bar-resend/container";

import "./notifications-bar.scss";

function NotificationsBar(props) {
  const { notificationData } = props;

  // console.log("notification data:  ", notificationData)
  const renderNotificationVariantBasedOnResend = () => {
    switch (notificationData.category) {
      case "site-sharing-sent":
        return (
          <NotificationBarWithResendAndCancel
            notificationData={notificationData}
          />
        );
      case "node-sharing-sent":
        return (
          <NotificationBarWithResendAndCancel
            notificationData={notificationData}
          />
        );
        case "user-group-sharing-sent":
        return (
          <NotificationBarWithResendAndCancel
            notificationData={notificationData}
          />
        );
      case "user-group-sharing-received":
        return (
          <NotificationsBarAcceptReject notificationData={notificationData} />
        );
        case "USER_INVITED_TO_GROUP__INVITER":
        return (
          <NotificationsBarOnlyText notificationData={notificationData} />
        );
        case "USER_INVITED_TO_GROUP__INVITEE":
          return (
            <NotificationsBarAcceptReject notificationData={notificationData} />
          );
        case "user-group-sharing-recieved":
        return (
          <NotificationsBarAcceptReject notificationData={notificationData} />
        );
      case "site-sharing-received":
        return (
          <NotificationsBarAcceptReject notificationData={notificationData} />
        );
      case "node-sharing-received":
        return (
          <NotificationsBarAcceptReject notificationData={notificationData} />
        );
      case "site-sharing-accepted-sender":
        return <NotificationsBarOnlyText notificationData={notificationData} />;
      case "LOCATION":
        // return <></>
        return <NotificationsBarOnlyText notificationData={notificationData} />;
      case "LOCATION_TRIGGER":
        return <></>
      default:{
        console.log("default:", notificationData.category)
        return <></>
      }
      // case "site-sharing-accepted-receiver":
      //   return <NotificationsBarOnlyText notificationData={notificationData} />;
      // case "node-sharing-accepted-sender":
      //   return <NotificationsBarOnlyText notificationData={notificationData} />;
      // case "node-sharing-accepted-receiver":
      //   return <NotificationsBarOnlyText notificationData={notificationData} />;
    }
  };

  return renderNotificationVariantBasedOnResend();
}

export default NotificationsBar;
