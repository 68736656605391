import React, { useContext, useEffect } from "react";
import "./AccessManagement.scss";
import {
  Error,
  Loading,
  Map,
  MapVariants,
} from "../../../../../../atoms";
import { InteriorContext } from "../../../../../../templates/interior/components/InteriorContext";
import ModifyAccessLevels from "./components/ModifyAccessLevels";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
// import UserCards from "./components/UserCards";
import AddUserToAccessGroup from "./components/AddUserToAccessGroup";
import UserInfo from "./components/UserInfo";
import AddNewAccessGroup from "./components/AddNewAccessGroup";
// import { InteriorPageHeadingWithCTA, InteriorPageHeadingWithMenu } from "../../../../../molecules";
import { useWindowWidth } from "@react-hook/window-size";
import { MemberCardEvent } from "../../../../../../molecules";
import { SCREEN_SIZE } from "../../../../../../../utils/layout";
import { REQ_STATE } from "../../../../../../../utils";

function ManageAccessComponent(props) {
  const {
    siteDetail,
    geoFenceData,
    members,
    error,
    accessGroups,
    deleteAccessGroup_loading,
    handleDeleteAccessGroup,
    siteId,
    getAccessGroup,
  } = props;
  const width = useWindowWidth();

  const interiorContext = useContext(InteriorContext);

  useEffect(() => {
    // Closing drawer when user leaves component
    return () => interiorContext.closeDrawer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEditAcccessGroup = (name, permissions, groupID) => {
    interiorContext.openDrawer();
    interiorContext.setDrawerComponent(
      <ModifyAccessLevels
        groupName={name}
        permissions={permissions}
        groupID={groupID}
        siteId={siteId}
        getAccessGroup={getAccessGroup}
      />
    );
  };

  const handleAddUserToAccessGroup = (name, groupID) => {
    interiorContext.openDrawer();
    interiorContext.setDrawerComponent(
      <AddUserToAccessGroup
        groupName={name}
        groupID={groupID}
        siteId={siteId}
        getAccessGroup={getAccessGroup}
      />
    );
  };

  const handleUserCardClicked = (user, groupID) => {
    interiorContext.openDrawer();
    interiorContext.setDrawerComponent(
      <UserInfo
        user={user}
        groupID={groupID}
        siteId={siteId}
        getAccessGroup={getAccessGroup}
      />
    );
  };

  const handleCreateAcccessGroup = () => {
    interiorContext.openDrawer();
    interiorContext.setDrawerComponent(
      <AddNewAccessGroup siteId={siteId} getAccessGroup={getAccessGroup} />
    );
  };

  // const renderGroups = () => {
  //   return (
  //     <div className="manage_access-container container">
  //       <div className="create-accessgroup-button-div">
  //         <Button
  //           className="create-accessgroup-button"
  //           onClick={() => handleCreateAcccessGroup()}
  //         >
  //           Create group
  //         </Button>
  //       </div>
  //       {accessGroups.data.results.map((accessGroup) => {
  //         const { groupID, name, users, permissions } = accessGroup;

  //         return (
  //           <div key={groupID} className="accesslevel-container">
  //             <div className="accesslevel_heading-container">
  //               <div>
  //                 <TextAtom
  //                   variant={TextVariants.interiorPageSubHeading}
  //                   className="accesslevel-container__heading"
  //                 >
  //                   {name}
  //                 </TextAtom>
  //               </div>
  //               <div className="accessgroup-buttons">
  //                 <Button
  //                   variant={ButtonVariants.primaryOutlined}
  //                   className="accessgroup-button"
  //                   onClick={() =>
  //                     handleEditAcccessGroup(name, permissions, groupID)
  //                   }
  //                 >
  //                   View Or Modify
  //                 </Button>

  //                 <Button
  //                   variant={ButtonVariants.dangerOutlined}
  //                   onClick={() => handleDeleteAccessGroup(groupID)}
  //                   loading={deleteAccessGroup_loading}
  //                   className="accessgroup-button"
  //                 >
  //                   Delete
  //                 </Button>
  //               </div>
  //             </div>
  //             <div className="user_cards-container">
  //               <UserCards
  //                 handleUserCardClicked={(...args) =>
  //                   handleUserCardClicked(...args, groupID)
  //                 }
  //                 users={users}
  //               />
  //               <div
  //                 className="add-user"
  //                 onClick={() => handleAddUserToAccessGroup(name, groupID)}
  //               >
  //                 <FontAwesomeIcon
  //                   icon={faPlusCircle}
  //                   className="add-user-icon"
  //                 />
  //                 <TextAtom variant={TextVariants.small}>Add {name}</TextAtom>
  //               </div>
  //             </div>
  //           </div>
  //         );
  //       })}
  //     </div>
  //   );
  // };

  const handleMembersData = () => {
    // eslint-disable-next-line default-case
    switch (members.state) {
      case REQ_STATE.initialized:
      case REQ_STATE.loading:
        return <Loading />;
      case REQ_STATE.error:
        return <Error />;
      case REQ_STATE.loaded:
        return renderMemberCards();
    }
  };

  // const renderChildRoutes = (timer_delay, updateSiteID, getSiteID) => (
  //   <Switch>
  //     <Route exact path={`${path}`}>
  //       <MemberCard handleSelectMember={handleSelectMember} member={member}/>
  //     </Route>
  //     <Route path={`${path}/:userId`}>
  //       <UserDetails />
  //     </Route>
  //   </Switch>
  // );

  // console.log("display sites:  ", geoFenceData)


  const handleSelectMember = (member) => {
    // setSelectedUser(member);

    // viewMember(member);
  };

  const renderMemberCards = () => {
    const { data } = members;
    if (!data || data.totalResults === 0) {
      return <p>No Members currently.</p>;
    }
    const { results } = data;
    return results.map((member) => <MemberCardEvent handleSelectMember={handleSelectMember} member={member} />);
  };

  const extractLocationData = (results) => {
    // console.log("results: ", results)
    // const locations = [];

    // console.log("results: ", results)
    // results.forEach((location) => {
      const { name, long, lat } = results;
      const locationData = {
        name,
        longLat: [lat, long],
      };
      // locations.push(locationData);
    // });
    return locationData;
  };


  const renderMembers = () => {
    // When there are no sites
    if (siteDetail === undefined) return <></>;//noSites();

    // When there are sites
    return (
      // {<div className="members-containerr">}
      <>
        <Map
          geoFenceData={geoFenceData}
          variant={MapVariants.withMaker}
          className="map"
          zoom={15}
          locationData={extractLocationData(siteDetail)}
          scrollWheelZoom={SCREEN_SIZE.desktop(width)}
        />
        {/* {handleMembersData()} */}
        { <div className="members-cards-container">{handleMembersData()}</div> }
        </>
      // </div>
    );
  };
  const renderGroups = () => {

    return (
      <div className="members__page-event">
        {/* <InteriorPageHeadingWithCTA heading="Sites">
          { <Button
            variant={ButtonVariants.primary}
            withIcon
            onClick={handleCreateSiteCTAClicked}
          >
            <FontAwesomeIcon icon={faPlus} className="icon" />
            Create New Site
          </Button> }
        </InteriorPageHeadingWithCTA> */}
        {renderMembers()}
        {/* {handleSitesData()} */}
      </div>
    )
  };
  const handleAccessGroupData = () => {
    // if (error) return <Error />;
    // if (accessGroups) return renderGroups();
    // return <Loading />;
    return renderGroups();
  };

  return handleAccessGroupData();
}

export default ManageAccessComponent;
