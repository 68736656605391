import React, { useEffect, useState, useCallback, useRef } from 'react';
import "./FeedNotModal.scss";
import { PostCard, NotificationsBar } from "../../molecules";
import { useFetch } from "../../../utils/hooks";
import { API_ENDPOINTS } from "../../../utils/constants";
import { notify } from "../../../utils";
import { REQ_STATE } from "../../../utils";
import { Error, Loading, Page, TextAtom } from "../../atoms";




const RenderFeedData = (props) => {
  const { feed, feedItems, ...rest } = props;
  const posts = feedItems;

  const postCards = () => {
      if (posts?.length !== 0){
        return posts.map((post) => (
          // <div className="post">{post.body}</div>
          <PostCard  post={post} />
        ));
      }else return;
  };

  switch (feed.state) {
    case REQ_STATE.initialized:
    case REQ_STATE.loading:
      return <Loading />;
    case REQ_STATE.error:
      return <Error />;
    case REQ_STATE.loaded:
      return (<>{postCards()}</>);
  }
};

function FeedNotModal(props) {
  const { notifications, feed, show, onClose, ...rest } = props;
  const [showFeed, setShowFeed] = useState(true);
  const fshow = showFeed;
  const { post: createPost, del: deleteMember } = useFetch();
  const [postText, setPostText] = useState('');
  const textareaRef = useRef(null);
  const [feedItems, setFeedItems] = useState(feed?.data?.results || []);



  useEffect(() => {
    setFeedItems(feed?.data?.results || [])
  }, [feed]);

  // useEffect(() => {
  //   console.log("feedItem 0:", feedItems[0])
  // }, [feedItems]);

  const RenderNotData = () => {

    if(notifications.state !== "loaded")
      return null

    const nots = notifications.data.results;
  
    const notCards = () => {
        if (nots?.length !== 0){
          return nots.map((notificationData) => (
            // <div className="post">{post.body}</div>
            <NotificationsBar notificationData={notificationData}/>
          ));
        }else return;
    };
    return (
      <>
        {notCards()}
      </>
    );
  };

  const handleFeed = () => {
    setShowFeed(true)
  };

  const handleNot = () => {
    setShowFeed(false)
  };

  const onPostClick  = (post) => {

    const textareaValue = textareaRef.current.value;
    // console.log(textareaValue); // This will log the current text in the textarea

    if(textareaValue.length <= 0) return;

    createPost(API_ENDPOINTS.post, {
      body: textareaValue,
      type: "TEXT"
    })
          .then((res) => {
            if (res.success === true) {
              // notify.success("Like updated successfully", {
              //   toastId: "LikesUpdateSuccess",
              // });
              const item = feedItems?.find((it) => 
                it.user?.id === res.data.user);
              if (item) {
                res.data.user = item.user;
              } 
              setFeedItems([res.data, ...feedItems]);
              setPostText("")
            }
            if (res.success === false) {
              notify.error(res.message);
            }
          })
          .catch((error) => {
            notify.error(error.message);
          });
  };

  const handleTextAreaChange = (event) => {
    setPostText(event.target.value);
  };

  return (
    <div className={`feednot-modal-container${show ? '-show' : ''}`}>
      <div className="feednot-select-container"> 
       <button className="feednot-select-feed" onClick={handleFeed}>Feed</button>
       <button className="feednot-select-not" onClick={handleNot}>Notification</button>
      </div>

      <div className={`feed-post${fshow ? '' : '-noshow'}`}>
      <textarea
        className="feed-post-textarea"
        placeholder="Enter text..."
        value={postText}
        onChange={handleTextAreaChange}
        ref={textareaRef}
      />
        <button className="feed-post-btn"
          onClick={() => onPostClick()} >Post</button>
      </div>
      
      <div className={`feed-container${fshow ? '' : '-noshow'}`}>
        {/* {RenderFeedData()} */}
        <RenderFeedData feed={feed} feedItems={feedItems}/>
      </div>

      <div className={`notification-container${!fshow ? '' : '-noshow'}`}>
        {RenderNotData()}
      </div>
  </div>
  );
}

export default FeedNotModal;
