import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { notify } from "../../../..//utils";
import { API_ENDPOINTS } from "../../../../utils/constants";
import { useFetch } from "../../../../utils/hooks/useFetch";
import ManageUserComponent from "./component";

import { getMembers } from "../../../../redux/myGroup/actions";
import { MyGroupComponent } from "../../my-group/component"
import { Route, Switch, useRouteMatch } from "react-router";
import UserDetails from "../../users/components/user-details/container";
import { ROUTES } from "../../../../utils/constants";
import { useHistory } from "react-router";

function ManageUser() {
  const { path } = useRouteMatch();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    // dispatch(getMembers());
  }, [dispatch]);

  const members = useSelector((state) => state.members);

  const viewMember = (member) => {
    // console.log("Member:  ", member)
    history.push(ROUTES.userDetails(member.user.username))
  }

 // const { siteId } = useParams();
  // const { loading, error, response, get: getUserGroup } = useFetch();
  // const {
  //   loading: deleteUserGroup_loading,
  //   del: deleteUserGroup,
  // } = useFetch();

  // useEffect(() => {
  //   getUserGroup(API_ENDPOINTS.userGroupsData());
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // useEffect(() => {
  //   // getUserGroup(API_ENDPOINTS.userGroupsData());
  //   console.log("response:   ", response)
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [response]);

  // const handleDeleteUserGroup = async (groupId) => {
  //   try {
  //     const res = await deleteUserGroup(
  //       API_ENDPOINTS.userGroup( groupId)
  //     );

  //     if (res.status === "success") {
  //       notify.success("Group deleted successfully", {
  //         toastId: "groupDeleted",
  //       });
  //       getUserGroup(API_ENDPOINTS.userGroupsData());
  //     }
  //     if (res.status === "error")
  //       notify.error(res.error.message, {
  //         toastId: "groupDeletedFail",
  //       });
  //   } catch (error) {
  //     notify.error(error.error.message, {
  //       toastId: "groupDeletedFail",
  //     });
  //   }
  // };

  const renderChildRoutes = (members) => (
    <Switch>
      <Route exact path={`${path}`}>
        <MyGroupComponent members={members} viewMember={viewMember}/>
      </Route>
      <Route path={`${path}/:userId`}>
        <UserDetails />
      </Route>
    </Switch>
  );

  return (
    renderChildRoutes(members)
  );
}

export default ManageUser;
