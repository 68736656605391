import React from "react";
import DeviceSummaryComponent from "./component";
import { useSelector } from "react-redux";
import { useParams } from "react-router";

function DeviceSummary(props) {
  //const { deviceDetail, nodeId } = useParams();
  const { deviceDetail, nodeId } = props;

  // const devices = useSelector((state) => state.devices);

  // const deviceData = devices.data?.results;
  // const deviceDetailr = deviceData?.find((device) => device.id === nodeId);

  return <DeviceSummaryComponent deviceDetail={deviceDetail} nodeId={nodeId} />;
}

export default DeviceSummary;
