/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import DeviceDetailComponent from "./component";

function DeviceDetails() {
  const { nodeId } = useParams();

  const devices = useSelector((state) => state.nodes);
  const deviceData = devices.data?.results;
  const deviceDetail = deviceData?.find((device) => device.nodeID === nodeId);

  return <DeviceDetailComponent nodeId={nodeId} deviceDetail={deviceDetail} />;
}

export default DeviceDetails;
