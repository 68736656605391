import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { getSites } from "../../../../../../redux/sites/actions";
import {
  makeRequest,
  notify,
  NOTIFICATION_MESSAGES,
} from "../../../../../../utils";
import { API_ENDPOINTS, ROUTES } from "../../../../../../utils/constants";
import CreateSiteComponent from "./component";

function CreateSite() {
  const dispatch = useDispatch();
  const history = useHistory();
  const handleOnSubmit = async (data) => {
    try {
      const res = await makeRequest.post(API_ENDPOINTS.sites, data);
      if (res) {
        dispatch(getSites());
        notify.success(NOTIFICATION_MESSAGES.successCreateSite);
        history.push(ROUTES.sites);
      }
    } catch (err) {
      console.error(err);
    }
  };
  return <CreateSiteComponent handleOnSubmit={handleOnSubmit} />;
}

export default CreateSite;
