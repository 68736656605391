import React from "react";
import ProfileIcon from "../../../../../assets/images/default-profile-icon.png";
import {
  composeDateUsingTimestamp,
  DATETIME_FORMAT,
} from "../../../../../utils";
import {
  Button,
  ButtonVariants,
  TextAtom,
  TextVariants,
} from "../../../../atoms";
import "./NotificationsBarOnlyText.scss";

function NotificationsBarOnlyTextComponent(props) {
  const { notificationData, handleDeleteNotification, userData } = props;

  return (
    <div className="bullet_container">
      {/* <div>
        <img
          className="profile_image"
          src={userData === undefined ? ProfileIcon : userData.avtar}
          alt="userImage"
        />
      </div> */}
      <div className="bullet-text_container-only">
        <div className="bullet_text">
          <TextAtom variant={TextVariants.small}>
            {notificationData.message}
          </TextAtom>
        </div>
      </div>
      <div className="actions">
        {/* <Button
          variant={ButtonVariants.dangerOutlined}
          onClick={handleDeleteNotification}
        >
          Delete
        </Button> */}
        <TextAtom
          variant={TextVariants.small}
          className="timestamp_bullet-text"
        >
          {composeDateUsingTimestamp(
            notificationData.createdAt,
            DATETIME_FORMAT.dateAndTime
          )}
        </TextAtom>
      </div>
    </div>
  );
}

export default NotificationsBarOnlyTextComponent;
