import { INITIAL_STORE_STATE } from "../../utils";
import { ADD_MSGPROFILE, ADD_FOLLOWING, ADD_MEMLOCATIONS } from "./types";

export const msgProfileReducer = (state = INITIAL_STORE_STATE, action) => {
  const { type, payload } = action;
  
  switch (type) {
    case ADD_MSGPROFILE: {
      return { ...payload };
    }
    default:
      return state;
  }
};

export const followingReducer = (state = INITIAL_STORE_STATE, action) => {
  const { type, payload } = action;
  
  switch (type) {
    case ADD_FOLLOWING: {
      return { ...payload };
    }
    default:
      return state;
  }
};

export const memLocationReducer = (state = INITIAL_STORE_STATE, action) => {
  const { type, payload } = action;
  
  switch (type) {
    case ADD_MEMLOCATIONS: {
      return { ...payload };
    }
    default:
      return state;
  }
};
