// import { faPlus } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useHistory } from "react-router";
import { useWindowWidth } from "@react-hook/window-size";
import { REQ_STATE } from "../../../../../../utils";
import { ROUTES } from "../../../../../../utils/constants";
// import { SCREEN_SIZE } from "../../../../../utils/layout";
import DeviceCard from "../../../../../molecules/device-card/DeviceCard";
import {
  Button,
  ButtonVariants,
  Error,
  Link,
  Loading,
  TextAtom,
  TextVariants,
  Map,
  MapVariants,
} from "../../../../../atoms";
import "./DisplayDevices.scss";
import { useSelector } from "react-redux";

function DisplayDevicesComponent(props) {
  const { /*sites,*/ devices } = props;
  const history = useHistory();
  const width = useWindowWidth();
  const userIDLoggedIn = useSelector((state) => state.user?.data?.userID);

  const handleDeviceData = () => {
    // eslint-disable-next-line default-case
    switch (devices.state) {
      case REQ_STATE.initialized:
      case REQ_STATE.loading:
        return <Loading />;
      case REQ_STATE.error:
        return <Error />;
      case REQ_STATE.loaded:
        return renderDevices();
    }
  };

  const noDevices = () => {
    return (
      <TextAtom variant={TextVariants.regular}>
        Looks like you havent added sites yet! Feel free to{" "}
        <Link to={ROUTES.createSite}> add a site</Link>
      </TextAtom>
    );
  };


  const renderLocationCards = () => {
    // console.log("I am ", devices)
    if (devices.length === 0) {
      return (
        <div style={{ textAlign: "center", marginTop: "50px" }}>
          <TextAtom variant={TextVariants.extraLarge}>
            {" "}
            No devices found !! You can onboard one using InLo mobile App
          </TextAtom>
        </div>
      )
    } else {

      return Object.keys(devices).map((deviceData, i) => {
          console.log("device data:  2", devices[i])
          if(!(devices[i] === undefined)){
            return ( <DeviceCard
              // siteID={deviceData?.currentSiteID}
              nodeID={devices[i]?.id}
              type={devices[i]?.type}
              name={devices[i]?.name}
              discoveredAt={devices[i]?.createdAt}
              lastUpdatedAt={devices[i]?.updatedAt}
              lastRebooted={devices[i]?.lastRebooted}
            />)
          } else {
            return (null)
          }
        })
    }

  }

  const extractLocationData = (results) => {
    const locations = [];

    // console.log("results: ", results)
    results.forEach((location) => {
      const { name, longitude, latitude } = location;
      const locationData = {
        name,
        longLat: [latitude, longitude],
      };
      locations.push(locationData);
    });
    return locations;
  };

  const renderDevices = () => {

    console.log("renderDevices: ", devices)
    return (
      <div className="device-container">
        {/* { <Map
          variant={MapVariants.withMaker}
          className="map"
          locationData={extractLocationData(devices)}
          scrollWheelZoom={SCREEN_SIZE.desktop(width)}
        /> } */}
        <div className="device-location-cards">{renderLocationCards()}</div>

      </div>
    );
  };

  return (
    <div className="devices__page-main">
      {handleDeviceData()}
    </div>
  );
}

export default DisplayDevicesComponent;


