import React from "react";
import {
  Button,
  ButtonVariants,
  TextAtom,
  TextVariants,
} from "../../../../atoms";
import ProfileIcon from "../../../../../assets/images/default-profile-icon.png";
import {
  composeDateUsingTimestamp,
  DATETIME_FORMAT,
} from "../../../../../utils";

import "./NotificationsBarAcceptReject.scss";

function NotificationsBarAcceptRejectComponent(props) {
  const {
    notificationData,
    handleAcceptInvite,
    handleDeleteNotification,
    userData,
  } = props;

  const constructNotification = () => {
    return (
      <div className="bullet_container">
        <img
          className="profile_image"
          src={userData === undefined ? ProfileIcon : userData.avtar}
          alt="userImage"
        />
        <div className="bullet-text_container">
          <div className="bullet_text">
            <TextAtom variant={TextVariants.small}>
              {notificationData.message} &nbsp; at &nbsp;{" "}
            </TextAtom>
          </div>
          <TextAtom
              variant={TextVariants.small}
              className="timestamp_bullet-text"
            >
              {composeDateUsingTimestamp(
                notificationData.createdAt,
                DATETIME_FORMAT.dateAndTime
              )}
        </TextAtom>
          <div className="action-buttons_container">
            <Button
              className="action-button"
              variant={ButtonVariants.successOutlined}
              onClick={handleAcceptInvite}
            >
              Accept Invite
            </Button>
            <Button
              className="action-button"
              variant={ButtonVariants.dangerOutlined}
            >
              Reject Invite
            </Button>
          </div>
        </div>
        <div className="bullet_container-delete-container">
          <div className="X-container" onClick={handleDeleteNotification}>
            <div className="bullet_container-delete_button-container">X</div>
          </div>
          <div className="tooltip">Click to delete notification</div>
        </div>
      </div>
    );
  };

  return constructNotification();
}

export default NotificationsBarAcceptRejectComponent;
