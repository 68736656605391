import React from "react";
import { LocationCard } from "../../molecules";
import SiteDeviceCard from "../../molecules/site-device-card/SiteDeviceCard";
import "./ProfileRelatedDisplay.scss";
const siteDeviceData = {
  active: false,
  bleFirmwareVersion: null,
  bleMacAddress: null,
  claimed: true,
  currentSiteID: "31f1888affd44317b3aa0a3bae6cf4b1",
  currentSiteName: null,
  discoveredAt: 1615134232816,
  discoveredBy: "emulator",
  distance: 0,
  floorID: null,
  floorName: null,
  icon: null,
  iconColor: "#9B51E0",
  intersectionCoordinates: [null],
  ipAddress: null,
  kmeansResult: null,
  lastRebooted: 1615134232816,
  lastUpdatedAt: 1615134232816,
  latitude: null,
  longitude: null,
  macAddress: "11:b6:1a:f7:73:dd",
  movingAverage: [null],
  name: "Sundar",
  nearestNodeID: null,
  nearestNodeName: null,
  nodeID: "0d11a017b7164f238dfdfac6d0c9feb5",
  nwkSecret: null,
  onboardedSiteID: "31f1888affd44317b3aa0a3bae6cf4b1",
  orientation: 0,
  outliers: [null],
  ownedBy: "af38ea6b894a40c8a879a3b86fde91bb",
  placed: true,
  region: "N",
  roomID: null,
  roomName: null,
  sixlowpanFirmwareVersion: null,
  sixlowpanMacAddress: null,
  type: "virtual_object",
  wifiFirmwareVersion: null,
  x: 0,
  y: 0,
};

function ProfileRelatedDisplay(props) {
  const { sites } = props;

  const renderSitesSection = () => {
    const siteLocations = sites.data.results;
    return siteLocations.map((site) => (
      <LocationCard key={site.siteID} site={site} className="card" />
    ));
  };
  const renderDevicesSection = () => {
    return (
      <div className="devices-section_profile-related-display">
        <SiteDeviceCard
          key={siteDeviceData.nodeID}
          type={siteDeviceData.type}
          name={siteDeviceData.name}
          discoveredAt={siteDeviceData.discoveredAt}
          lastUpdatedAt={siteDeviceData.lastUpdatedAt}
          lastRebooted={siteDeviceData.lastRebooted}
          //   onClick={() => handleSiteDeviceCardClicked(siteDeviceData)}
        />
      </div>
    );
  };

  return (
    <div className="profile-related-display_container">
      <div className="profile-related-display_element">
        {renderDevicesSection()}
      </div>
      <div className="sites-section_profile-related-display">
        {renderSitesSection()}
      </div>
    </div>
  );
}

export default ProfileRelatedDisplay;
