import React, {useEffect, useState} from "react";
import { useHistory} from "react-router";
import { useDispatch, 
        useSelector} from "react-redux";
import { getNodes } from "../../../redux/nodes/actions";
import { getFeed } from "../../../redux/feed/actions";
import { getNotifications } from "../../../redux/notifications/actions";
import { getSites } from "../../../redux/sites/actions";
import { getMsgProfile,
        getMyLocations, 
        getMyFollowing } from "../../../redux/msgProfile/actions";
import { getMsgConvo } from "../../../redux/msgConvo/actions";
import MapWithCurrentLocationComponent from "./component";

import {
  StateActions,
} from "../../../utils";
import { ADD_DEVICES } from "../../../redux/devices/types";
import { getLocations } from "../../../redux/locations/actions";
import { ADD_FEED } from "../../../redux/feed/types";


function MapWithCurrentLocation() {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    //Feed
    dispatch(getFeed());
    //Members
    dispatch(getMsgProfile());
    dispatch(getMyFollowing());
    dispatch(getMyLocations());
    //chat
    dispatch(getMsgConvo());
    //notifications
    dispatch(getNotifications());
    //dispatch(getNodes());
    //Sites
    dispatch(getSites());
    //devices?
    dispatch(getLocations());
  }, [dispatch]);

  const msgProfile = useSelector((state) => state.msgProfile);
  const msgConvo = useSelector((state) => state.msgConvo);
  const devices = useSelector((state) => state.devices);
 // const feed = useSelector((state) => state.feed);
  // const sites = useSelector((state) => state.sites);

  if(devices.state === "loaded"){
    localStorage.setItem('devices', JSON.stringify(devices));
  }else{
    const actiond = new StateActions(ADD_DEVICES);
    dispatch(actiond.loading);
    dispatch(actiond.loaded(JSON.parse(localStorage.getItem('devices'))));
  }

  // if(feed.state !== "loaded"){
  //   const actiond = new StateActions(ADD_FEED);
  //   dispatch(actiond.loading);
  //   //dispatch(actiond.loaded(JSON.parse(localStorage.getItem('devices'))));
  // }

  // var dev = JSON.parse(localStorage.getItem('devices'))
  // const devr = Array.from(dev);

  // console.log("user locations: ", localStorage.getItem('id'))

  return <MapWithCurrentLocationComponent msgConvo={msgConvo} msgProfile={msgProfile}/>;
}

export default MapWithCurrentLocation;

