import React from "react";
import { Route, Switch, useHistory, useRouteMatch } from "react-router";
import { ROUTES } from "../../../../../../utils/constants";
import { Button, ButtonVariants } from "../../../../../atoms";
import { InteriorPageHeadingWithCTA, InteriorPageHeadingWithMenu } from "../../../../../molecules";
import { SubMenu } from "../../../../../organisms";
import ManageAccess from "./access-management/container";
import EditSite from "./edit/container";
import SiteDevices from "./site-devices/container";
import SiteSummary from "./summary/container";
import FloorPlan from "./floor-plan/container";
import {
  isSiteEditAllowed,
  isFloorPlanViewAllowed,
  isAccessManagementAllowed,
  isDeviceViewAllowed,
} from "./utils";

function SiteDetailComponent(props) {
  const { siteId, siteDetail } = props;
  const { path } = useRouteMatch();
  const history = useHistory();
  const childRoutesMenu = [
    { name: "Home", path: ROUTES.profileSiteDetails(siteId) },
  ];

  const handleAllSitesClicked = () => {
    history.push(ROUTES.userSites);
  };

  const updateChildRoutesMenuAccordingToPermissions = () => {
      childRoutesMenu.push({ name: "Edit", path: ROUTES.profileEditSite(siteId) });
      // childRoutesMenu.push({
      //   name: "People",
      //   path: ROUTES.manageAccess(siteId),
      // });
      childRoutesMenu.push({
        name: "Events",
        path: ROUTES.profileSiteManageAccess(siteId),
      });
    // if (isFloorPlanViewAllowed(siteDetail?.permissions))
    //   childRoutesMenu.push({
    //     name: "Floorplan",
    //     path: ROUTES.floorPlan(siteId),
    //   });
    // if (isDeviceViewAllowed(siteDetail?.permissions))
    //   childRoutesMenu.push({
    //     name: "Devices",
    //     path: ROUTES.siteDevices(siteId),
    //   });

    return childRoutesMenu;
  };

  const renderChildRoutes = () => (
    <Switch>
      <Route exact path={path}>
        <SiteSummary />
      </Route>
      <Route path={`${path}/edit`}>
        <EditSite siteId={siteId} />
      </Route>
      <Route path={`${path}/manage-access`}>
        <ManageAccess siteDetail={siteDetail}/>
      </Route>
      <Route path={`${path}/devices`}>
        <SiteDevices />
      </Route>
      <Route path={`${path}/floorplan`}>
        <FloorPlan />
      </Route>
      <Route path={`${path}/:siteId`}></Route>
    </Switch>
  );

  return (
    <div>
      <InteriorPageHeadingWithMenu spanName="submenu-span" heading="">
          { <SubMenu
          className="container"
          routeDetails={updateChildRoutesMenuAccordingToPermissions()}/> }
          <Button
          variant={ButtonVariants.primaryRight}
          withIcon
          onClick={handleAllSitesClicked}
        >
          All Sites
        </Button>
        </InteriorPageHeadingWithMenu>
      {renderChildRoutes()}
    </div>
  );
}

export default SiteDetailComponent;
