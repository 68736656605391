import React, { useState } from "react";
import { Error, Loading, Map, MapVariants } from "../../../../../../atoms";
// import House from "../../../../../../assets/images/house.jpeg";
import { REQ_STATE } from "../../../../../../../utils";
import "./SiteSummary.scss";
import { SiteSummaryCard } from "../../../../../../molecules";
import { useWindowWidth } from "@react-hook/window-size";
// import { SCREEN_SIZE } from "../../../../../../utils/layout";
// import { composeLocationData } from "../../../utils";

import { Route, Switch, useRouteMatch } from "react-router";
////import { Page } from "../../atoms";
//import { Interior } from "../../templates";
import DisplaySiteOutdoor from "./components/display-site-outdoor/container";
// import DisplaySiteIndoor from "./components/display-site-indoor/container";
// import { MapNav } from "../../../../../organisms";
// import { ROUTES } from "../../../../../../utils/constants";

function SiteSummaryComponent(props) {
  const { siteDetail, sites } = props;
  const width = useWindowWidth();
  const [state,setState] = useState("");
  const { path } = useRouteMatch();
  // const childRoutesMenu = [
  //   { name: "Out", path: ROUTES.mapWithCurrentLocation },
  // ];

  // let updateChildRoutesMenu = () => {
  //   childRoutesMenu.push({ name: "In", path: ROUTES.indoorSite(2,getSiteID()) });

  //   return childRoutesMenu;
  // };

  function updateSiteID(props){
    setState(props);
  }

  function getSiteID(){
    return state;
  }

  // const renderChildRoutes = (updateSiteID, getSiteID) => (
  //   // <Switch>
  //   //   <Route exact path={`${path}`}>
  //   //     <DisplaySiteOutdoor getSiteID={getSiteID} updateSiteID={updateSiteID}/>
  //   //   </Route>
  //   //   <Route path={`${path}`}>
  //   //     <DisplaySiteIndoor getSiteID={getSiteID} updateSiteID={updateSiteID}/>
  //   //   </Route>
  //   // </Switch>

  //   //if()
  // );
  
  // function SiteMapNav(props) {
  // const { routeDetails, className, ...rest } = props;
  // const classes = classnames(className, "map-nav");

//   const renderMenu = () => {
//   //   return routeDetails.map((route) => (
//   //     <Link
//   //       key={route.name}
//   //       activeClassName="map-nav-item-is-active"
//   //       className="map-nav-item"
//   //       to={route.path}
//   //       exact
//   //     >
//   //       {route.name}
//   //     </Link>
//   //   ));
//   // };

//   return (
//     <div >
//       <button
//         // activeClassName="map-nav-item-is-active"
//         className="map-nav-item"
//       >
//         Out
//       </button>
//       <button
//         // activeClassName="map-nav-item-is-active"
//         className="map-nav-item"
//       >
//         In
//       </button>
//     </div>
//   );
// }

// const getInitialState = () => {
//   setState({active: 'FIRST'});
// }
// //getInitialState()

// const outHandler = () => {
//   return(
//     <DisplaySiteOutdoor getSiteID={getSiteID} updateSiteID={updateSiteID}/>
//   )
// }


const handleInClick = () => {
  var active = state.active;
  if(active !== 'SECOND'){
    setState({
        active: 'SECOND'
    });
  }
}
const handleOutClick = () => {
  var active = state.active;

  if(active !== 'FIRST'){
    setState({
        active: 'FIRST'
    });
  }
}

console.log("props:  ", props);
  // console.log(siteDetail);
  const renderSiteSummary = () => {
     //getInitialState()
     //setState({active: 'FIRST'})
      // if(state.active === undefined)
      //   var active = 'SECOND'
      // else
      //   var active = state.active

    //console.log("state:  ", state.active)
    return (
      <div className="site-summary__component-summary-page">
        <div className="site-profile-header-background">
            </div>
        <div className="site-meta-data">
            <img className="house-image" src={siteDetail.picture} alt={siteDetail.address} />
          <div className="site-basic-details">
            <SiteSummaryCard siteDetail={siteDetail} />
          </div>
        </div>
        <div className="map-container">
          {/* <MapNav
            className="container"
            routeDetails={updateChildRoutesMenu()}
          /> */}
          {/* {renderChildRoutes(updateSiteID, getSiteID)} */}
          {/* {renderMenu()} */}
          <div className="map-buttons">
            <button
              onClick={handleOutClick}
              // activeClassName="map-nav-item-is-active"
              className="map-site-nav-item-out"
            >
              Out
            </button>
            <button
              onClick={handleInClick}
              // activeClassName="map-nav-item-is-active"
              className="map-site-nav-item-in"
            >
              In
            </button>
          </div>
          {/* {renderSwitch()} */}
          <div>
                {/* {active === 'FIRST' ? ( */}
                    <DisplaySiteOutdoor getSiteID={getSiteID} updateSiteID={updateSiteID}/>
                {/* ) : active === 'SECOND' ? (
                  <DisplaySiteIndoor getSiteID={getSiteID} updateSiteID={updateSiteID}/>
                ) : null} */}
            </div>
          {/* <Map
              variant={MapVariants.withMaker}
              className="map"
              locationData={composeLocationData(siteDetail)}
              scrollWheelZoom={SCREEN_SIZE.desktop(width)}
            /> */}
          </div>
          <div className="site-feed-container">
            <div className="site-feed">
            </div>
          </div>
      </div>
    );
  };

  const handlesiteDetails = () => {
    // eslint-disable-next-line default-case
    switch (sites.state) {
      case REQ_STATE.initialized:
      case REQ_STATE.loading:
        return <Loading />;
      case REQ_STATE.error:
        return <Error />;
      case REQ_STATE.loaded: {
        return renderSiteSummary();
      }
    }
  };

  return <div className="container">{handlesiteDetails()}</div>;
}

export default SiteSummaryComponent;
