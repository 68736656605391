/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import DeviceDetailComponent from "./component";

function DeviceDetails() {
  const { /*siteId,*/ nodeId } = useParams();

  //console.log("siterer :  ", siteId)

  // const sites = useSelector((state) => state.sites);
  // const siteData = sites.data?.results;
  // const siteDetail = siteData?.find((site) => site.siteID === siteId);

  const devices = useSelector((state) => state.nodes);
  const deviceData = devices.data?.results;
  const deviceDetail = deviceData?.find((device) => device.nodeID === nodeId);

  return <DeviceDetailComponent nodeId={nodeId} deviceDetail={deviceDetail} />;
}

export default DeviceDetails;
