import React from "react";
import EditDeviceComponent from "./component";
import { useDispatch, useSelector } from "react-redux";
import { useWindowWidth } from "@react-hook/window-size";
// import {
//   makeRequest,
//   NOTIFICATION_MESSAGES,
//   notify,
// } from "../../../../../../utils";
//import { API_ENDPOINTS, ROUTES } from "../../../../../../utils/constants";
//import { getSites } from "../../../../../../redux/sites/actions";
import { useHistory } from "react-router";

function EditDevice(props) {
  const { /*deviceDetail,*/ nodeId } = props;
  const width = useWindowWidth();
  const dispatch = useDispatch();
  const history = useHistory();

  // const sites = useSelector((state) => state.sites);
  // const siteData = sites.data?.results;
  // const siteDetail = siteData?.find((site) => site.siteID === siteId);

  // const devices = useSelector((state) => state.devices);
  const devices = useSelector((state) => state.devices);

  console.log("devices", devices)

  // const deviceData = devices.data?.results;
  // const deviceDetail = devices?.find((device) => device.id === String(nodeId));
  const handleDeleteDevice = async () => {
    // try {
    //   const res = await makeRequest.delete(API_ENDPOINTS.deleteSite(siteId));
    //   if (res) {
    //     notify.success(NOTIFICATION_MESSAGES.successDeleteSite);
    //     dispatch(getSites());
    //     history.push(ROUTES.sites);
    //   }
    // } catch (error) {
    //   console.error(error);
    //   notify.error(NOTIFICATION_MESSAGES.internalServerError);
    // }
  };

  // console.log("EditDevice 2", nodeId, deviceDetail)
  return <EditDeviceComponent
      // sites={sites}
      // siteDetail={siteDetail}
      // deviceDetail={deviceDetail}
      devices={devices}
      width={width}
      nodeId={nodeId}
      // handleDeleteSite={handleDeleteDevice}
    />
}

export default EditDevice;
