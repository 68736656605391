import React from "react";
import "./Devices.scss"
import { Route, Switch, useRouteMatch } from "react-router";
import DisplayDevices from "./components/display-devices/container";
import DeviceDetails from "./components/device-details/container";

function DevicesComponent(props) {
  const { msgProfile } = props;
  const { path } = useRouteMatch();

  const renderChildRoutes = () => (
    <Switch>
      <Route exact path={path}>
        <DisplayDevices />
      </Route>
      <Route path={`${path}/:nodeId/edit`}>
        <DeviceDetails />
      </Route>
    </Switch>
  );

  return (
    renderChildRoutes()
  );
}

export default DevicesComponent;
