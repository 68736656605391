import React, { useContext, useEffect } from "react";
import { REQ_STATE } from "../../../utils";
import { Error, Loading, Page, TextAtom, TextVariants, TextAlignVariants } from "../../atoms";
import { MemberCard } from "../../molecules";
import { Interior } from "../../templates";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./MyGroupComponent.scss";
import AddMember from "./components/AddMember/AddMember";
import { Route, Switch, useRouteMatch } from "react-router";
import { ROUTES } from "../../../utils/constants";
import { useHistory } from "react-router";
import { InteriorContext } from "../../templates/interior/components/InteriorContext";
import UserDetails from "../users/components/user-details/container";


export const MyGroupComponent = (props) => {
  const { members, viewMember } = props;
  const history = useHistory();
  const { path } = useRouteMatch();
  const interiorContext = useContext(InteriorContext);

  useEffect(() => {
    // Closing drawer when user leaves component
    return () => interiorContext.closeDrawer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectMember = (member) => {
    // setSelectedUser(member);

    viewMember(member);
  };

  // const viewMember = (member) => {
  //   history.push(ROUTES.userDetails(member.user.username))
  // }

  const handleMembersData = () => {
    // eslint-disable-next-line default-case
    switch (members.state) {
      case REQ_STATE.initialized:
      case REQ_STATE.loading:
        return <Loading />;
      case REQ_STATE.error:
        return <Error />;
      case REQ_STATE.loaded:
        return renderMembers();
    }
  };

  // const renderChildRoutes = (timer_delay, updateSiteID, getSiteID) => (
  //   <Switch>
  //     <Route exact path={`${path}`}>
  //       <MemberCard handleSelectMember={handleSelectMember} member={member}/>
  //     </Route>
  //     <Route path={`${path}/:userId`}>
  //       <UserDetails />
  //     </Route>
  //   </Switch>
  // );


  const renderMembers = () => {
    const { data } = members;
    if (!data || data.totalResults === 0) {
      return <p>No Members currently.</p>;
    }
    const { results } = data;
    return results.map((member) => <MemberCard handleSelectMember={handleSelectMember} member={member} />);
  };

  const handleAddMememberClick = () => {
    interiorContext.openDrawer();
    interiorContext.setDrawerComponent(<AddMember />);
  };
  
  return (
    <div className="members__page">
      <div className="add-member" onClick={handleAddMememberClick}>
        <FontAwesomeIcon icon={faPlusCircle} className="add-member-icon" />
        <TextAtom variant={TextVariants.regular}>Add Member</TextAtom>
      </div>
      {handleMembersData()}
    </div>
  );
};
