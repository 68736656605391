import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProfileComponent from "./component";
import { getMembers } from "../../../redux/myGroup/actions";
import { getMsgProfile } from "../../../redux/msgProfile/actions";
import { getMsgConvo } from "../../../redux/msgConvo/actions";
import { Route, Switch, useRouteMatch } from "react-router";
import UserDetails from "../users/components/user-details/container";
import { ROUTES } from "../../../utils/constants";
import { useHistory } from "react-router";
import { getLocations } from "../../../redux/locations/actions";
import { getSites } from "../../../redux/sites/actions";
import { getFeed } from "../../../redux/feed/actions";
import {
  StateActions,
} from "../../../utils";
import { ADD_DEVICES } from "../../../redux/devices/types";

function Profile() {
  const { path } = useRouteMatch();
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    dispatch(getFeed());
    dispatch(getMembers());
    dispatch(getMsgProfile());
    dispatch(getMsgConvo());
    dispatch(getLocations());
    dispatch(getSites());
  }, [dispatch]);
  const msgProfile = useSelector((state) => state.msgProfile);
  const msgConvo = useSelector((state) => state.msgConvo);
  const sites = useSelector((state) => state.sites);
  const user = useSelector((state) => state.user);
  const devices = useSelector((state) => state.devices);
  // const feed = useSelector((state) => state.feed);

  if(devices.state === "loaded"){
    localStorage.setItem('devices', JSON.stringify(devices));
  }else{
    const actiond = new StateActions(ADD_DEVICES);
    dispatch(actiond.loading);
    dispatch(actiond.loaded(JSON.parse(localStorage.getItem('devices'))));
  }

  return <ProfileComponent msgProfile={msgProfile} msgConvo={msgConvo} user={user} sites={sites} />;
}

export default Profile;
