import React from "react";
import { REQ_STATE, SCREEN_SIZE } from "../../../../../../../utils";
import {
  Button,
  ButtonVariants,
  Error,
  Loading,
  Map,
  MapVariants,
} from "../../../../../../atoms";
import { Detail } from "../../../../../../molecules";
import SiteAddressEditComponent from "./components/SiteAddressEditComponent";
import SiteCityEditComponent from "./components/SiteCityEditComponent";
import SiteCountryEditComponent from "./components/SiteCountryEditComponent";
import SiteNameEditComponent from "./components/SiteNameEditComponent";
import SiteZipCodeEditComponent from "./components/SiteZipCodeEditComponent";
import SiteLongLatEditComponent from "./components/SiteLongLatEditComponent";
import SiteEditAvatar from "./components/SiteEditAvatar";
import "./EditSite.scss";
import { composeLocationData } from "../../../utils";
import { isDeleteSiteAllowed } from "../utils";

function EditSiteComponent(props) {
  const { siteDetail, sites, width, handleDeleteSite } = props;

  // console.log("proper:   ", props)

  const handleSiteData = () => {
    // eslint-disable-next-line default-case
    switch (sites.state) {
      case REQ_STATE.initialized:
      case REQ_STATE.loading:
        return <Loading />;
      case REQ_STATE.error:
        return <Error />;
      case REQ_STATE.loaded:
        return renderSiteDetails();
    }
  };

  const renderDetails = () => {
    const details = [];
    const {
      name,
      long,
      lat,
      zipcode,
      country,
      city,
      address,
      permissions,
      picture,
      siteId
    } = siteDetail;

    details.push({
      label: "img",
      value: (
        <img
          className="account_page-profile_pic"
          src={picture}
          alt="site profile pic"
        />
      ),
      editComponent: SiteEditAvatar,
       //editComponent: EditProfilePic,
    });

    details.push({
      label: "Site name",
      value: `${name}`,
      editComponent: SiteNameEditComponent,
    });
    details.push({
      label: "Address",
      value: address,
      editComponent: SiteAddressEditComponent,
    });
    details.push({
      label: "City",
      value: city,
      editComponent: SiteCityEditComponent,
    });
    details.push({
      label: "Country",
      value: country,
      editComponent: SiteCountryEditComponent,
    });

    details.push({
      label: "Lat/Long",
      value: `${lat}/ ${long}`,
      editComponent: SiteLongLatEditComponent,
    });
    details.push({
      label: "zipcode",
      value: zipcode,
      editComponent: SiteZipCodeEditComponent,
    });

    if (isDeleteSiteAllowed(permissions)) {
      details.push({
        label: "",
        value: (
          <Button variant={ButtonVariants.danger} onClick={handleDeleteSite}>
            Delete Site
          </Button>
        ),
      });
    }

    return details.map((detail) => (
      <Detail
        key={detail.label}
        label={detail.label}
        value={detail.value}
        EditComponent={detail?.editComponent}
      />
    ));
  };

  const renderSiteDetails = () => {
    return (
      <div className="site_edit-container">
        <div className="site_edit">{renderDetails()}</div>
        <div className="map-container">
          <Map
            variant={MapVariants.withMaker}
            className="map"
            locationData={composeLocationData(siteDetail)}
            scrollWheelZoom={SCREEN_SIZE.desktop(width)}
          />
        </div>
      </div>
    );
  };
  return <div>{handleSiteData()}</div>;
}

export default EditSiteComponent;
