import React from "react";
import classnames from "classnames";
import "./Text.scss";
import variables from "../../../styles/variables.scss";
import PropTypes from "prop-types";

export const TextVariants = {
  regular: {
    class: "regular",
    tag: "p",
  },
  small: {
    class: "small",
    tag: "p",
  },
  large: {
    class: "large",
    tag: "p",
  },
  extraSmall: {
    class: "extra-small",
    tag: "p",
  },
  extraLarge: {
    class: "extra-large",
    tag: "p",
  },
  extraExtraLarge: {
    class: "extra-extra-large",
    tag: "p",
  },

  pageHeading: {
    class: "page-heading",
    tag: "h1",
  },
  interiorPageHeading: {
    class: "interior-page-heading",
    tag: "h1",
  },
  interiorPageSubHeading: {
    class: "interior-page-sub-heading",
    tag: "h1",
  },
  headingOne: {
    class: "heading-one",
    tag: "h1",
  },
};

export const TextAlignVariants = {
  center: "center"
};

export const ColorVariants = {
  black: "black",
  red: "red",
};

export const TextSizes = {
  xs: variables.fontXS,
  s: variables.fontS,
  m: variables.fontM,
  l: variables.fontL,
  xl: variables.fontXL,
  xxl: variables.fontXXL,
};

export const TextColors = {
  primaryDarkBlue: variables.primaryDarkBlue,
  primaryTeal: variables.primaryTeal,
  primaryGrey: variables.primaryGrey,
  secondaryGrey: variables.secondaryGrey,
  tertiaryGrey: variables.tertiaryGrey,
  primaryBlack: variables.primaryBlack,
  secondaryBlack: variables.secondaryBlack,
  primaryWhite: variables.primaryWhite,
  primaryRed: variables.primaryRed,
};

export const TextWeights = {
  extraBold: variables.fontExtraBold,
  bold: variables.fontBold,
  semiBold: variables.fontSemiBold,
  regular: variables.fontRegular,
  light: variables.fontLight,
};

function TextAtom(props) {
  const {
    children,
    variant,
    className,
    style,
    size,
    color,
    weight,
    disabled,
    textAlign,
    ...rest
  } = props;

  const styles = {
    fontSize: size ? size : "",
    color: color ? color : "",
    fontWeight: weight ? weight : "",
    textAlign: textAlign ? textAlign : "",
    ...style,
  };
  const classes = classnames(
    `text__atom`,
    `text__atom-${variant.class}`,
    disabled ? "text__atom-disabled" : "",
    className
  );
  const Tag = `${variant.tag}`;

  return (
    <Tag className={classes} style={styles} {...rest}>
      {children}
    </Tag>
  );
}

export default TextAtom;

TextAtom.propTypes = {
  /**
   * Text to be displayed.
   */
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.node,
  ]),
  /**
   * Pick your variant
   */
  variant: PropTypes.oneOf(Object.values(TextVariants)),
  /**
   * Preferred font weight
   */
  weight: PropTypes.oneOf(Object.values(TextWeights)),
  /**
   * Preferred font color
   */
  color: PropTypes.oneOf(Object.values(TextColors)),
  /**
   * Preferred font size
   */
  size: PropTypes.oneOf(Object.values(TextSizes)),
};

TextAtom.defaultProps = {
  variant: TextVariants.regular,
};
